<template>
  <div
    class="ydpdfWrap"
    v-loading="saveLoading"
  >
    <div class="ydpdfWrap-btn">
      <el-button
        v-print="printObj"
        type="primary"
        size="small"
        icon="el-icon-printer"
      >打印</el-button>
    </div>
    <div
      class="content"
      id="printObjAll"
    >
      <h1 style="text-align: center;margin-bottom: 20px;padding-top: 20px;font-size: 16px;">{{ pdfModelTitle }}</h1>
      <div class="item">
        <el-descriptions
          class="margin-top"
          title=""
          :column="2"
          size="small"
          border
        >
          <el-descriptions-item
            :span="1"
            v-for="(item, idx) in orderObj"
            :key="idx"
            labelClassName="my-label"
          >
            <template slot="label"> {{ item.name }} </template>
            <div style="width: 200px">
              <span v-if="item.value">{{ printWaybillObj[item.key][item.value] }}</span>
              <span v-else>{{ printWaybillObj[item.key] }}</span>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div
        class="item"
        v-for="(item, idx) in urlsObj"
        :key="idx"
      >
        <div v-if="printWaybillObj[item.key] && printWaybillObj[item.key].length">
          <el-descriptions
            title=""
            direction="vertical"
            :column="2"
            size="small"
            border
          >
            <el-descriptions-item
              :label="item.name"
              :span="2"
              :labelStyle="{ 'text-align': 'center' }"
              labelClassName="my-label"
            >
              <template v-if="forEachList(printWaybillObj[item.key], false).length">
                <div
                  style="display: flex; align-items: center; flex-wrap: wrap"
                  id="printMe"
                >
                  <el-image
                    style="width: 49%; height: 300px"
                    :src="urlIt"
                    fit="contain"
                    v-for="(urlIt, urlIdx) in forEachList(
                                        printWaybillObj[item.key],
                                        false
                                    )"
                    :key="urlIdx + urlIt"
                    class="imgItem"
                  ></el-image>
                </div>
              </template>
              <template v-if="forEachList(printWaybillObj[item.key], true).length">
                <div
                  v-for="(urlIt, urlIdx) in forEachList(printWaybillObj[item.key], true)"
                  :key="urlIdx + urlIt"
                >
                  <el-link
                    type="primary"
                    @click="downLoadFile(urlIt)"
                    class="textItem"
                  >{{ urlIt
                                    }}</el-link>
                </div>
              </template>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div
        class="item"
        v-if="mapList.length"
        style="width: 75%; display: flex;align-items: center;justify-content: center;margin: 0 auto;"
      >
        <TXmap
          ref="map"
          :list="mapList"
          :isPrint="true"
        > </TXmap>
      </div>
    </div>
  </div>
</template>
<script>
import TXmap from "@/components/commonCmpt/TXmap";
export default {
  data() {
    return {
      flag: {
        loading: false,
      },
      printObj: {
        id: "printObjAll",
        popTitle: "",
        // modalMessage: '',
        // clickMounted: () => {
        // },
      },
      saveLoading: false,
      orderObj,
      urlsObj,
      printWaybillObj: {},
      pdfModelTitle: "",
      mapList: []
    };
  },
  created() {
    this.printObj.popTitle = this.pdfModelTitle;
    this.printWaybillObj = JSON.parse(localStorage.getItem("printWaybillObj"))


    console.log('this.printWaybillObj', this.printWaybillObj)

    this.pdfModelTitle = localStorage.getItem("pdfModelTitle")
    this.mapList = JSON.parse(localStorage.getItem("mapList"))
    document.title = this.pdfModelTitle;
  },
  methods: {
    isNotImage(filename) {
      return !/\.(jpg|jpeg|png|gif)$/i.test(filename); // 返回结果为true或false，根据文件名是否符合图像格式后缀进行判断
    },

    downLoadFile(url) {
      //url:下载文件的url
      let a = document.createElement("a");
      a.href = url;
      a.click();
    },

    forEachList(list, type) {
      console.log('list', list)
      let imgList = list.filter((item) => this.isNotImage(item) == type);
      return imgList.length ? imgList : [];
    },
    outPutPdfFn() {
      let vm = this;
      const A4_WIDTH = 592.28;
      const A4_HEIGHT = 841.89;
      vm.$nextTick(() => {
        // dom的id。
        let target = document.getElementById('printObjAll');
        let pageHeight = target.scrollWidth / A4_WIDTH * A4_HEIGHT;
        // 获取分割dom，此处为class类名为item的dom
        let lableListID = document.getElementsByClassName('item');
        // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
        for (let i = 0; i < lableListID.length; i++) {
          let multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].offsetHeight) / pageHeight);
          if (this.isSplit(lableListID, i, multiple * pageHeight)) {
            let divParent = lableListID[i].parentNode; // 获取该div的父节点
            let newNode = document.createElement('div');
            newNode.className = 'emptyDiv';
            newNode.style.background = '#ffffff';
            let _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i].offsetHeight);
            newNode.style.height = _H + 30 + 'px';
            newNode.style.width = '100%';
            let next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
            // 判断兄弟节点是否存在
            // console.log(next);
            if (next) {
              // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
              divParent.insertBefore(newNode, next);
            } else {
              // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
              divParent.appendChild(newNode);
            }
          }
        }
        // 传入title和dom标签，此处是 #content
        // 异步函数，导出成功后处理交互
        this.getPdf(this.pdfModelTitle, '#printObjAll').then(() => {
          this.$message({
            type: 'success',
            message: '导出成功'
          });
        })
          .catch((error) => {
            this.$message({
              type: 'error',
              message: '导出失败，请重试'
            });
          });
      });
    },
    isSplit(nodes, index, pageHeight) {
      // 计算当前这块dom是否跨越了a4大小，以此分割
      if (nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight && nodes[index + 1] && nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight) {
        return true;
      }
      return false;
    },
  },
  components: {
    TXmap,
  },
}
let orderObj = [
  {
    name: "运单号",
    key: "WaybillInfo",
    value: "WaybillID",
  },
  {
    name: "结算单号",
    key: "InvoiceTaskID",
  },
  {
    name: "货主单位",
    key: "WaybillInfo",
    value: "GoodsOwnerName",
  },
  {
    name: "服务商",
    key: "SupplierName",
  },
  {
    name: "司机姓名",
    key: "WaybillInfo",
    value: "DriverName",
  },
  {
    name: "车牌号",
    key: "WaybillInfo",
    value: "CarNumber",
  },
  {
    name: "起始地",
    key: "WaybillInfo",
    value: "OriginAddress",
  },
  {
    name: "到达地",
    key: "WaybillInfo",
    value: "ArriveAddress",
  },
  {
    name: "装货时间",
    key: "WaybillInfo",
    value: "LoadingDatetime",
  },
  {
    name: "签收时间",
    key: "WaybillInfo",
    value: "SignDatetime",
  },
  {
    name: "货品名称",
    key: "WaybillInfo",
    value: "GoodsName",
  },
  {
    name: "装货净重",
    key: "WaybillInfo",
    value: "LoadNetWeight",
  },
  {
    name: "运费",
    key: "WaybillInfo",
    value: "CarriageTotalPrice",
  },
];

let urlsObj = [
  {
    name: "司机证照",
    key: "DriverUrls",
  },
  {
    name: "车辆证照",
    key: "CarUrls",
  },
  {
    name: "运输凭证",
    key: "TransportUrls",
  },
  {
    name: "结算单凭证",
    key: "InvoiceTaskUrls",
  },
  {
    name: "资金凭证",
    key: "FundUrls",
  },
  {
    name: "合同凭证",
    key: "ContractUrls",
  },
  {
    name: "其他凭证",
    key: "OtherUrls",
  },
];
</script>
<style lang="scss" scoped>
.ydpdfWrap {
  width: 98%;
  padding: 20px 0px;
  margin: 0 auto;

  .ydpdfWrap-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }

  .mb16 {
    // margin-bottom: 16px;

    .imgItem {
      border: 0.5px solid #ebeef5;
      display: flex;
      justify-content: center;
      align-content: center;

      &:nth-child(2n) {
        border-left: none;
      }

      &:nth-child(n + 3) {
        border-top: none;
      }
    }

    .textItem {
      border-bottom: 0.5px solid #ebeef5;
      height: 30px;
      line-height: 30px;
    }
  }
}
</style>

<style lang="scss">
.ydpdfWrap {
  .my-label {
    font-weight: bold !important;
  }
}
</style>